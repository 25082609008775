@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";

.Resourelist{
    .button{background-color: #ef476f;
        color:white;
        margin:1rem;
        font-size: 0.9rem;
        padding: 0.8rem 0.8rem;
        @include md{
            padding: 0.2rem 0.2rem;
        }
    }
      
        .button:hover{background-color: #fb8500;
        color:white;
        transition: .5s;}

        .category{
            color:#ef476f;
            font-size: 1.1rem;
        }
        h7{
           .title{
            color: $black;
            font-weight: 600;
           }
        }
        .pdficon{
            color:red;
            justify-content: center;
        }
        .pdffile{
            font-size: 0.9rem;
            color:#979797;
        }
}