// BG
$main-bg: #2a3447;
$soft-bg: #384256;
$dark-bg: #222b3c;
//TEXT
$main-color: white;
$soft-color: #ddd;
$dark-color: #2a3447;
$black:black;
$white:white;
$grey:#ddd;


//Buttons
$main-button: white;
$soft-button: #ddd;
$dark-button: #2a3447;
$pink-button:#ef476f;
$skyblue:#0a9396;

$hr:#ececec;

//GRID
$pink-grid: #ef476f;
$green-grid: #8db743;
$orange-grid: #fb8500;
$blue-grid: #009bdc;
$light-pink:#ec94a9;
$pink-border:#dc74c2;


//Font
$nunito:"nunito", sans-serif;
$font-Heading:"Signika", sans-serif;

