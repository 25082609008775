img.logometro{
  width: 150px;
  height: 120px;
  margin-right: 10px;
}


img.logotmn {
  width: 190px;
  height: 70px;
  margin-top: 2rem;
}



ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #f9f8f8;
  position: relative;
  margin-top: 6rem;
  border-top: 1px solid #ef476f;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ef476f;
  font-size: 30px;
  float: left;
  margin-top: 8px;

}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #0e0d0d;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #151515;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #0a0a0a;
  line-height: 28px;
}

.footer-social-icon span {
  color: #080808;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #ffffff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #ef476f;
}

.footer-widget-heading h3 {
  color: #030303;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ef476f;
}

.footer-widget ul li {
  display: inline-block;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #ef476f;
}

.footer-widget ul li a {
  color: #121212;
  text-transform: capitalize;
}

.copyright-area {
  background: #dcdbdb;
  padding: 20px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #0b0b0b;
}

.copyright-text p a {
  color: #ef476f;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ef476f;
}

.footer-menu li a {
  font-size: 14px;
  color: #0a0a0a;

}


@media (max-width: 575px) {
  img.logotmn {
    width: 120px;
    height: 40px;
    margin-top: 2rem;
  }
  img.logometro{
    width: 100px;
    height: 85px;
    margin-right: 10px;
  }
}