@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";


//Title style for Header title
.header{
    padding-bottom:2rem;
    padding-top:0rem;
    .head-Contact {
    font-size:2rem;}
}
 