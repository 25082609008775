.galleryimg {	
	position: relative;		
}
.galleryimg img {vertical-align: middle;}
 
  .imgGrid {
	display: block;
	width: 95%;
	height: 100%;
	border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 0px 3px 10px 5px #858484;
  
  }
  
  .galleryimg .content {
	position: absolute;
	bottom: 0;
	background: rgb(0, 0, 0); /* Fallback color */
	background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
	color: #f1f1f1;
	width: 60%;
	height: 0;
	transition: .5s ease;
  }
  .galleryimg:hover .content {
	height: 6rem;
  }

  .text {
	position: absolute;
	top: 40%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
  }
  
  
