@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";

 
 //HOME ABOUT US
 #abouthome{
    padding-left: 3rem;
    display:flex;

    img{ width:110%;  height:130%;  padding-right: 0rem;}

    h2{margin-bottom: 1.5rem; font-size: 2rem;
        @include xl{
            font-size: 1.5rem; text-align: center;}}

    .content-left{
     justify-content: center;
     align-items: center;
        line-height: 1.7rem;
        vertical-align: middle;
        padding-top: 6rem;
        font-family: $nunito;
       
        font-weight: 400;
        letter-spacing: 0.07rem;
        word-wrap: wrap;
      p{font-size: 0.9rem;
        @include xl{
            font-size: 0.8rem }}
      @include xl{
        padding-top: 3rem; } }
        .content-right{align-items: center;}

        .btn-about{background-color: #ef476f;
            color:white;
            padding: 0.5rem 2rem; 
            margin-top:2rem;
            font-size: 1.2rem;
            @include md{
                padding: 0.3rem 0.7rem; 
                margin-top:0.5rem;
                font-size: 0.7rem; }
        &:hover{background-color: #fb8500;  color:white;}}
  }

  //HOME COLOR CARDS 
  .color-grid{
    .card-title { font-size: 1em; text-align: center;
        font-weight: 500; 
        @include md{    font-size: 0.7em;}
        @include sm{    font-size:0.4rem;}
    }
        .card4 {background-color: #d12e7b;}
        .card1{background-color: #8db743;}
        .card2{background-color: #e59431;}
        .card3{background-color: #009bdc;}
  }
 
  //HOME MEETING SLIDER
  #carouselExampleInterval{
          margin-bottom: 2rem;
    .meet{
        width:100%;
        height:200px;
        // background-color:#fcb256;
//         background: rgb(34,193,195);
//         background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,0.8297443977591037) 100%);
               text-align: center;
               background-color: #d12e7b;
               background-image: linear-gradient(43deg, #d12e7b 0%, #8db743 30%, #009bdc 66%, #e59431 100%);
               

        padding-top: 3rem;
        padding-bottom: 3rem;
        color:white;
        
    }
      .sliderDate{font-size: 1rem;
      font-weight: 400; line-height: 1rem; text-align:center;
      @include md{    font-size: 0.8em;}
      @include sm{    font-size:0.6rem;}
    }
      .Sliderhost{font-size: 1.3rem;
        @include md{    font-size: 1rem;}
        @include sm{    font-size:0.4rem;}}
  }

  //HOME CONTACT CARDS

  .contactcards{
    padding-top:3rem;

    .btn-contact{background-color: #ef476f;
        color:white;
        margin:1rem;
        font-size: 0.9rem;}
      
      .btn-contact:hover{background-color: #fb8500;
        color:white;
        transition: .5s;}
      
        .card-contact{ padding:2rem 1rem;
            border-radius:2rem;
          transition: .5s;
          box-shadow: 10px 1px 6px 2px #ddd;
        }
      
          .card-body{ text-align: center;}
          .card-contact:hover {
            box-shadow: 0px 3px 10px 5px #ddd; }
            
       h3{  margin: 13px 0 5px; line-height: 1rem;}
       .card-office { font-size: 0.95em; 
        font-weight: 500; color: #d12e7b;}
      
      .card-text{font-size: 0.9rem; font-weight: 500; }
      .icon{color:#d12e7b}
  }