@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";


.Meetevent{
    display: grid;
    font-family: 'Nunito Sans', sans-serif;
   
 
 h7{
    font-weight: 600;
    color: $pink-grid
 }
.firstGrid{
    flex-direction: column;
    overflow: hidden; 
    width:70%;
    
}
       
.secondgrid{
    flex-direction: column;
    margin-left: 2rem;
}

.icon{  
    color: $blue-grid;
    align-items: center;
}
.button{background-color: #ef476f;
        color:white;
        margin:1rem;
        font-size: 0.9rem;
        border-radius: 100%;
        padding: 0.8rem 0.8rem;
    }
      
        .button:hover{background-color: #fb8500;
        color:white;
        transition: .5s;}
        p{
            white-space: nowrap; 
            text-overflow: ellipsis;
            color:#a7a7a7
        }
       
}
        
 