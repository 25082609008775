/* MEDIA QUERY FOR COMPONENTS */

  /* ALL MEDIA QUERIES */
 @media (max-width: 500px) {
  .content-left{line-height: 1.7rem;}
  }

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .card { border-radius: 0%;}
  #thumbs{width:120%;  height:130%;  padding-left: 0rem;}
  #abouthome{padding-left: 6rem;}
 }

/* // Large devices (desktops, 992px and up) */
@media (max-width: 992px) { 
  .card-title { font-size: 0.75rem;}
  #thumbs{display:none}
  .content-left{padding-top: 2rem;}
  #abouthome{padding-left: 3rem; }
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (max-width: 1150px) {
  #abouthome{padding-bottom: 3rem; }
  #hidabout{display: none;}
  #thumbs{width:120%;  height:130%;  padding-left: 0rem;}

}@media (min-width: 992px) {
  #abouthome{height:480px}
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { }

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}