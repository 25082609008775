@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";


.event-date-tag-daynum{
    color: $pink-grid
}
.event-title event-title-link{
    color: $pink-grid
}
