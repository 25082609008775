@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";

.content{
 font-family: 'Nunito Sans', sans-serif;
 .smallHead{
   color:$blue-grid;
   font-weight: 900;
   font-size: 1.2rem;
   letter-spacing: 0.08rem;
   margin-top: 1rem;
   @include md {font-size: 1rem;}
 }

 .bigHead{
   color:$pink-grid;
   font-weight: 900;
   font-size: 1.6rem;
   letter-spacing: 0.08rem;
   padding-top: 1rem;
   @include md {font-size: 1.5rem; padding-top: 0.8rem; }
 }
   .Header{  background: url("../../Images/colorGrid.jpg") center center / cover no-repeat ;
    height:15rem;
  display: flex;
  align-items: center;
  
  justify-content: center;
  @include lg{
   width: 100%;}
   .heading{
      font-size: 3rem;
    text-align: center;
    font-weight: 900;
     background-color: rgba(219, 216, 216, 0.737);
   padding:1rem;
   color:$pink-grid;
    @include lg{
       font-size: 1.7rem;}
    @include md{
      font-size: 1.1rem;
      font-weight: 800; }}}


      .Intro {
         white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis; 
         overflow: visible;
         text-align: center;
         padding-top: 1rem;
         font-size: 1.2rem;
         color: $dark-color;
         @include lg{font-size: 1rem; }
         @include smd{
            
            font-size: 0.9rem;
         }
      }

      .aimContent{
         text-align: justify;
         padding-top: 2rem;
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         grid-auto-rows: minmax(100%, auto);  grid-auto-flow: dense;
         gap: 5%;
         justify-content: center;
         @include lg{
            grid-template-columns: repeat(1, 1fr);
         }
        
      }
    .principleContent{
      grid-column: span 2;
      font-size: 1rem;
      @include md{font-size: 0.9rem;}
      @include smd{padding-left: 1rem;}
    }

    .image{
      position:relative;
      justify-content: center;
      img{
         width:100%;
         height:65%;
         position: absolute;
         bottom:10%;
         left:10%;
         @include xl{
            width:100%;
            height:48%;
            left:6%;
            bottom:25%;
      }
      @include lg{
         display: none;
      }
      }
    }

    .terms{
      p{
         font-size: 1rem;
      @include md{font-size: 0.9rem;}
      }
    }
      
}