@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";

.upcoming-meeting{ font-family: 'Nunito Sans', sans-serif;}

.Calender{
    margin-top: 2rem;
    height:3rem;}

   #upcoming{
 padding: 0.5rem 1rem;
  border: none;
  background-color:inherit;
  margin-left: 0rem;}
   

  /* Part-1 */
   /* //Meeting Status message */
   .upcoming-event   {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    background-color:#dbdbdb;
    width:80%;}

    .upcoming-meeting   {
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      background-color:#dbdbdb;
      width:80%;
      height:40%;

    @include md{
      width: 100%;
    }
   }



  /* Styles for the event card wrapper */
.event-card {
    /* background-color:rgb(242, 250, 253); */
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    width:50%;
    margin-left: 5rem;
  }
  
  /* Styles for the event date and time */
  .event-datetime {
    font-size: 14px;
    color: #333;}
  
  /* Styles for the event title */
  .event-title{
    font-size: 22px;
    color:black;
    margin: 0.22rem 0;
  color:#ef476f;}
  
  .host-place{
    display: flex;
    flex-direction: row;
  }
  
  /* Styles for the event venue */
  .event-venue-title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
 
  
  /* Styles for the event description */
  .event-description p {
    font-size: 14px;
    color: #0b0b0b;
  align-items: center;
}

.event-date-tag {
  display: inline-block;
  padding: 3px;
  border-radius: 4px;
  margin-left: 8%;
  margin-top: 3rem;
}

/* Styles for the month */
.event-date-tag-month {
  display: block;
  font-size: 12px;
  font-weight: light;
  text-transform: uppercase;
  color: #333;
  text-align: center;
  line-height: 70%;
}

/* Styles for the day */
.event-date-tag-daynum {
  display: block;
  font-size: 24px;
  font-weight: bold;
  color:#ef476f;
  font-size: 1.2rem;
}

/* Styles for the year */
.event-date-tag-year {
  display: block;
  font-size: 12px;
  font-weight: light;
  text-align: center;
  color: #333;
  line-height: 70%;}
  
  /* Styles for the "Read more" link */
  /* .read-more {
    color:black;
  } */
  /* part-3 */
  /* body of Event Items */
 .Event-container{
    margin-left: 14rem;
    margin-top: 2rem;
 }
 
 /* part-4 */
 /* body  */
 .meetbody
 {
    background-color: rgb(243, 243, 243);
   
 }
.iconcolor{color: #009bdc;
  align-items: center;
}

 .meeting-container{margin-left:12rem; padding-top: 3rem;}
 .place{margin-left: 2rem;}


/*----------------------------------------------------------------------------*/
.header{
  position:relative;
}
.headerSearch {
  margin-left: 8rem;
  height: 50px;
  background-color: white;
  border: 3px solid #febb02;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0px;
  border-radius: 5px;
  position: absolute;
  bottom: -55px;
  width: 100%;
  max-width: 1024px;
}
.headerIcon {
  color: lightgray;
}
.headerSearchItem {
  display: flex;
  align-items: center;
  gap: 10px;
}
.headerSearchInput {
  border: none;
  outline: none;
}
.headerSearchText {
  color: lightgray;
  cursor: pointer;
}
.date {
  position: absolute;
  top: 40px;
  z-index: 2;
}
.headerBtn {
  background-color: #febb02;
  color: white;
  font-weight: 500;
  border: none;
  padding: 5px 14px;
  cursor: pointer;
  
  
}

 /*----------------------------------------------------------------------------*/
 @media screen and (max-width: 1200px) {}

  @media screen and (max-width: 768px){   }

@media (min-width: 576px) { }
@media (max-width: 768px) { }

@media (max-width: 992px) {
  .place{margin-left: 0rem;}
  .host-place{
    display: flex;
    flex-direction: column;  }
     .meeting-container{margin-left:2rem;}
     .event-title{font-size: 1.2rem;}
     .event-venue-title {
      font-size: 0.75rem;    }
      .event-datetime {
        font-size: 0.75rem;}
 }
 

@media (min-width: 1200px) { }
@media (min-width: 1400px) { }