.head-Contact{
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
}
.contact-us {
    margin-top: 5%;
}
.about-us {
    margin-top: 5%;
}
.Bala{
    width:5rem; height:8rem;
    border:1px sloid green;
}
