@import "../../Styles/Variables.scss";
@import "../../Styles/responsive.scss";

//NAVBAR
.navbar{
    border-bottom: 1.5px solid $grey;
    @include md{
       padding-top:1rem;}
.logo{
    width:20rem;
    height:3rem;
    padding-left: 1rem;
    @include sm{
        width:14rem;
        height:1.9rem;}}

.navbar-toggler{
    border:none;
padding-right: 1rem;}

.navbar-toggler-icon{
width:1em;}

ul{margin-left: 6rem;
    font-family: "Josefin Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 10px 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: flex;
    word-wrap: break-word;
    @include lg{
    margin-left:0rem;
      margin-top:5px   }
  li{
    padding:0 0.6rem; }}
 
.nav-link{ color:#080708;
    display: flex;
    flex-wrap: nowrap;
    @include xxl{
        font-size: 11px;
        display: flex;
        flex-wrap: nowrap;
        &:hover{
            color:$pink-button;}}
      @include lg{
        font-size: 9px;}
        @include sm{
        font-size: 12px; }
      &:hover{
        color:$pink-button;}}
}

//BANNER
/* IMAGE BANNER */


  .carousel-inner{
    .banner{
        width:100%;
        Height:700px;
      background: url("../../Images/68_edited.jpg") center center / cover no-repeat;
      }
      .banner2{
        width:100%;
        Height:700px;
      background: url("../../Images/BannerCrop.JPG") center center / cover no-repeat;
      }
      .banner3{
        width:100%;
        Height:700px;
      background: url("../../Images/39.JPG") center center / cover no-repeat;
      }
  }

 
