/*Style for header */
.header{
    padding-top: 5%;
    text-align: center;}

.searchbar{display: flex;
    align-items: center;
    background-color:#f8f8f8;
  height:100%;
  padding-left:0.45rem;
  padding-right: 0.45rem;
}
  .container-style {
    display: flex;
    flex-direction: row;    
    border: 1px solid #e7e7e7;
    }
    .inputs{padding: 0.45rem 0 0.45rem 0}
  #viewer{border:none; width:100%; padding-top:0.4rem; font-size:1rem; font-weight: 400; }
  h3 {margin-bottom: 10px; }

  select {padding: 0rem 0.3rem 0rem 0.3rem;
    font-size: 0.9rem;
    border: none;
    background-color: white;
    border: 0.2rem solid #ececec;
    border-radius: 4px;
   width:100%;
    margin-left:0rem;
    height:30px;
    font-weight: 350;} 

.form-control{ width:99%; vertical-align: middle; height:30px;}
hr{color:#fab338;
    background-color: #fab338;
    height: 0.05rem;
    width:70%;
    margin-left: 15%;
    margin:none;}

  
  
 
.grid{margin-left: 1rem;} 
 
/* Resource card  */
.r-card{ margin-top: 3rem;
 border-bottom: 1px solid  #ececec;
 padding-bottom: 0.5rem;}

.Buttons-grp{ padding-top: 0.5rem;}

a{color: #005d57; text-decoration: none;}
.Resource-list{max-height: 100%;
  max-width:80%;
  margin-top:3rem;}



  /* Media Screen */

@media screen and (max-width: 1200px) {
  .Resource-list{margin-left:7rem;
    margin-right:0;}}

  @media screen and (max-width: 768px){
    .Resource-list{ margin-left:3rem;
      font-size: 0.8rem;}
    .Buttons-grp {margin-top:0.6rem;
      font-size: 0.8rem;} 
    .btn{font-size: 0.8rem;}
  .header{font-size: 0.8rem;}
h1{font-size: 1.3rem;}}

@media (min-width: 576px) {
  
 }
@media (max-width: 768px) {
  .container-style { display: flex;
    flex-direction: column;}}

@media (min-width: 992px) { }
@media (min-width: 1200px) { }
@media (min-width: 1400px) { }